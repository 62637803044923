import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  LinearProgress,
  Modal,
  Paper,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getDaysInMonth, setDate, sub } from "date-fns";
import Body from "./Body";
import { DatePicker } from "@mui/x-date-pickers";
import MenuIcon from "@mui/icons-material/Menu";
import { UserNS, User } from "services/user";
import { Moderation, ModerationNS } from "services/moderationTeams";
import { StatusValue } from "shared/ScheduleData";
import UserView from "./UserView";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAuth } from "shared/auth";
import { Department, DepartmentNS } from "services/departments";

export interface Cell {
  month: number;
  day: number;
}

const Schedule = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { currentUser } = useAuth();
  const [selectedStatus, setSelectedStatus] = useState<StatusValue>("folga");
  const fullViewSchedule =
    currentUser?.department === "TI" ||
    currentUser?.displayName === "Andreia Alves" ||
    currentUser?.displayName === "Márcia Campos" ||
    currentUser?.displayName === "JESSICA PORTELA VALERIO BARBOSA" ||
    currentUser?.displayName === "Lucas Peng" ||
    currentUser?.displayName === "Jaqueline Sousa" ||
    currentUser?.displayName === "Isabela Gonçalves";
  const canSeeModeration = currentUser?.department === "Moderação de Conteúdo";
  const [teamViews, setTeamViews] = useState<any[]>([]);
  const [moderationTeams, setModerationTeams] = useState<Moderation[]>([]);
  const [department, setDepartment] = useState<Department[]>([]);
  const [value, setValue] = useState();
  const [selected, setSelected] = React.useState<Date | null>(new Date());
  const [cells, setCells] = useState<Date[]>([]);
  const [open, setOpen] = React.useState(false);
  const [viewUser, setViewUser] = useState<User | null>(null);
  const [expanded, setExpanded] = useState(true);

  function SeatCounter(month: number, date: number) {
    let count = 0;
    users.map((user) => {
      const status = user.schedule?.getStatus(month, date);
      if (status === "trabalhoP") {
        count++;
      }
    });
    const finalResult = 50 - count;
    return (
      <p
        className={
          finalResult < 0
            ? "text-white bg-red-500 font-bold"
            : "text-blue-iest font-bold"
        }
      >
        {finalResult}
      </p>
    );
  }

  useEffect(() => {
    if (fullViewSchedule) {
      UserNS.listSync((users) => setUsers(users));
      DepartmentNS.list((department) =>
        setDepartment([
          ...department.sort((first, second) =>
            first.name.localeCompare(second.name)
          ),
        ])
      );
      ModerationNS.list((moderation) =>
        setModerationTeams([
          ...moderation.sort((first, second) =>
            first.name.localeCompare(second.name)
          ),
        ])
      );
    } else if (currentUser?.displayName === "Leandro Freitas") {
      UserNS.listSync((users) => setUsers(users));

      DepartmentNS.list((department) =>
        setDepartment([
          ...department.filter(
            (department) =>
              department.name === "Contábil" || department.name === "Fiscal"
          ),
        ])
      );
    } else if (
      currentUser?.displayName === "Aniely Silva" ||
      currentUser?.displayName === "Heloisa Maria"
    ) {
      UserNS.listSync((users) => setUsers(users));
      ModerationNS.list((moderation) =>
        setModerationTeams([
          ...moderation.sort((first, second) =>
            first.name.localeCompare(second.name)
          ),
        ])
      );

      DepartmentNS.list((department) =>
        setDepartment([
          ...department.filter(
            (department) => department.name === "E-Commerce"
          ),
        ])
      );
    } else if (currentUser?.department === "Moderação de Conteúdo") {
      ModerationNS.list((moderation) =>
        setTeamViews([
          ...moderation.sort((first, second) =>
            first.name.localeCompare(second.name)
          ),
        ])
      );
      UserNS.listSync((users) =>
        setUsers(
          users.filter((user) => user.department === currentUser?.department)
        )
      );
    } else {
      DepartmentNS.list((department) =>
        setTeamViews([
          ...department
            .sort((first, second) => first.name.localeCompare(second.name))
            .filter(
              (department) => department.name === currentUser?.department
            ),
        ])
      );
      UserNS.listSync((users) =>
        setUsers(
          users.filter((user) => user.department === currentUser?.department)
        )
      );
    }
  }, [fullViewSchedule, currentUser]);

  useEffect(() => {
    setValue(teamViews[0]?.name);
  }, [teamViews]);

  useEffect(() => {
    if (department && moderationTeams) {
      setTeamViews(
        [...moderationTeams, ...department].sort((first, second) =>
          first.name.localeCompare(second.name)
        )
      );
    }
  }, [department, moderationTeams]);

  useEffect(() => {
    if (!selected) {
      setCells([]);
      return;
    }
    const selectedPrevious = sub(selected, { months: 1 });
    const firstPart = [...Array(getDaysInMonth(selectedPrevious))]
      .map((val, index) => {
        return setDate(selectedPrevious, index + 1);
      })
      .slice(-5);
    const secondPart = [...Array(getDaysInMonth(selected))].map(
      (val, index) => {
        return setDate(selected, index + 1);
      }
    );
    setCells([...firstPart, ...secondPart]);
  }, [selected]);

  const triggerUserView = (user: User) => {
    setViewUser(user);
    setOpen(true);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleClick = (user: User, cell: Date) => {
    setUsers(
      [...users].map((u) => {
        if (u.id === user.id) {
          user.schedule?.set(cell, selectedStatus);
          UserNS.update(user.id, { schedule: user.schedule });
          return { ...user };
        } else {
          return u;
        }
      })
    );
    // updateUser(user.uid, { schedule: user.schedule })
  };

  const handleClear = () => {
    if (
      confirm(
        "Você tem certeza que quer fazer isso? Essa ação apagará a escala desta equipe neste mês"
      )
    ) {
      setUsers(
        users.map((user) => {
          if (user.moderationTeam === value || user.department === value) {
            user.schedule?.clear(selected?.getMonth());
            UserNS.update(user.id, { schedule: user.schedule });
            return { ...user };
          } else {
            return user;
          }
        })
      );
    }
  };

  const sortByName = () => {
    setUsers((users) => [
      ...users.sort((first, second) =>
        first.displayName.localeCompare(second.displayName)
      ),
    ]);
  };
  const sortByPeriod = () => {
    setUsers((users) => [
      ...users.sort((first, second) =>
        first.period!.localeCompare(second.period!)
      ),
    ]);
  };

  return (
    <div>
      <div className="flex mb-4">
        <DatePicker
          label="Mês"
          value={selected}
          onChange={(newValue) => setSelected(newValue)}
          renderInput={(params) => <TextField {...params} />}
          views={["month", "year"]}
        />
        <div className="flex flex-col space-y-2 ml-4">
          <div className="flex">
            <div
              className={`rounded-md w-6 h-6 bg-yellow-600 cursor-pointer ${
                selectedStatus === "folga"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("folga")}
            ></div>
            <p className="text-base ml-1 mr-4">Folga</p>
            <div
              className={`rounded-md w-6 h-6 bg-emerald-600 cursor-pointer ${
                selectedStatus === "ferias"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("ferias")}
            ></div>
            <p className="text-base ml-1 mr-4">Férias</p>
            <div
              className={`rounded-md w-6 h-6 bg-white cursor-pointer ${
                selectedStatus === "trabalho"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("trabalho")}
            ></div>
            <p className="text-base ml-1 mr-4">Trabalho</p>
            {canSeeModeration && (
              <>
                <div
                  className={`rounded-md w-6 h-6 bg-pink-600 cursor-pointer ${
                    selectedStatus === "trabalhoB"
                      ? "border-2 border-gray-900"
                      : "border border-gray-400"
                  }`}
                  onClick={() => setSelectedStatus("trabalhoB")}
                ></div>
                <p className="text-base ml-1 mr-4">Trabalho Barueri</p>
                <div
                  className={`rounded-md w-6 h-6 bg-yellow-300 cursor-pointer ${
                    selectedStatus === "trabalhoS"
                      ? "border-2 border-gray-900"
                      : "border border-gray-400"
                  }`}
                  onClick={() => setSelectedStatus("trabalhoS")}
                ></div>
                <p className="text-base ml-1 mr-4">Trabalho Sorocaba</p>
              </>
            )}
          </div>
          <div className="flex">
            <div
              className={`rounded-md w-6 h-6 bg-red-600 ${
                selectedStatus === "trabalhoP"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("trabalhoP")}
            ></div>
            <p className="text-base ml-1 mr-4">Trabalho Paraiso</p>
            <div
              className={`rounded-md w-6 h-6 bg-violet-600 cursor-pointer ${
                selectedStatus === "feriado"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("feriado")}
            ></div>
            <p className="text-base ml-1 mr-4">Feriado</p>
            <div
              className={`rounded-md w-6 h-6 bg-blue-600 cursor-pointer ${
                selectedStatus === "outros"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("outros")}
            ></div>
            <p className="text-base ml-1">Outros</p>
          </div>
        </div>
      </div>
      {value && (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(e, newValue) => setValue(newValue)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {teamViews.map((team) => (
                <Tab key={team.name} label={team.name} value={team.name} />
              ))}
            </TabList>
          </Box>
          {teamViews &&
            teamViews.map((team) => (
              <TabPanel
                key={team.name}
                value={team.name}
                style={{ padding: "24px 0" }}
              >
                {users.length === 0 && <LinearProgress />}
                <div className="flex">
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              padding: "0.25rem",
                              left: 0,
                              position: "sticky",
                              background: "white",
                              zIndex: 3,
                            }}
                          >
                            <MenuIcon
                              className="ml-2"
                              onClick={(e) => setExpanded(!expanded)}
                            />
                          </TableCell>
                          {cells.map((cell, index) => (
                            <TableCell
                              style={{
                                padding: "0.5rem",
                                lineHeight: 1,
                              }}
                              key={index}
                              align="center"
                            >
                              {SeatCounter(cell.getMonth(), cell.getDate())}
                              <p>{format(cell, "dd/LL")}</p>
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              padding: "0.5rem",
                              top: 37,
                              left: 0,
                              position: "sticky",
                              background: "white",
                              zIndex: 3,
                            }}
                          >
                            {expanded ? (
                              <div className="whitespace-nowrap">
                                Sortear por:{" "}
                                <button className="mr-2" onClick={sortByName}>
                                  Nome
                                </button>
                                <button onClick={sortByPeriod}>Período</button>
                              </div>
                            ) : (
                              <>&nbsp;</>
                            )}
                          </TableCell>
                          {cells.map((cell, index) => (
                            <TableCell
                              style={{ padding: "0.375rem", top: 37 }}
                              key={index}
                              align="center"
                            >
                              {format(cell, "eeeeee", { locale: ptBR })}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <Body
                        expanded={expanded}
                        triggerUserView={triggerUserView}
                        cells={cells}
                        team={team.name}
                        users={users}
                        handleClick={handleClick}
                        // handleContextMenu={handleContextMenu}
                      />
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            ))}

          <p className="mb-4 text-sm text-gray-600">
            Esse painel de Escala tem salvamento automático.
          </p>
          {/* <p className='mb-4 text-sm text-gray-600'>Clique com Botão Direito na célula para mais opções</p>
				<p className='mb-4 text-sm text-gray-600'>Para desfazer as mudanças, recarregue a página</p>
				<Button className='ml-4' variant='contained' onClick={() => {
					Promise.all(users.map(user =>
						UserNS.update(user.id, { schedule: user.schedule }),
					)).then(() => alert('As mudanças form salvas com sucesso.'))
				}}>Salvar</Button> */}

          <Button onClick={handleClear} variant="contained">
            Limpar
          </Button>

          <Modal
            open={open}
            onClose={() => {
              setViewUser(null);
              setOpen(false);
            }}
          >
            <Box sx={style}>
              <UserView
                handleClick={handleClick}
                user={viewUser}
                date={selected}
                cells={cells}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </Box>
          </Modal>
        </TabContext>
      )}
    </div>
  );
};

export default Schedule;
